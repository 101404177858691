
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import * as StatServices from '@/services/StatServices'
import { format } from "date-fns";
import { nlBE } from "date-fns/locale";


@Component
export default class UniqueNewVisitorsPerDayChart extends Vue {

    group = 'day'

    get chartOptions() {
        return {
            chart: {
                id: 'vistors',
                toolbar: {
                    export: {
                        csv: {
                            filename: 'nieuwe-bezoekers-per-dag',
                            columnDelimiter: '|',
                            categoryFormatter(x: any) {
                                return new Date(x).toDateString()
                            },
                            headerCategory: 'date',
                            headerValue: 'new_visitors',
                        },
                    }
                }
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    formatter: (val: any) => {
                        // return format date
                        if (this.group === 'day') {
                            return format(new Date(val), 'dd MMM yyyy', {
                                locale: nlBE
                            })
                        } else {
                            return format(new Date(val), 'MMM yyyy', {
                                locale: nlBE
                            })
                        }

                    }
                }
            },
            dataLabels: {
                enabled: false
            },

        }
    }


    series = [] as any[]

    loading = false;

    data = [] as Array<{ date: string, new_visitors: number }>

    async mounted() {
        this.loading = true;
        this.data = await StatServices.GetUniqueNewVisitorsPerDay();
    }

    @Watch('group', { immediate: true })
    @Watch('data')
    async onGroupChange() {
        if (this.group === 'day') {
            this.series = [{
                name: 'Nieuwe bezoekers',
                data: this.data.map((e: any) => [new Date(e.date).toISOString(), e.new_visitors])
            }]
        } else {
            const groupedData: { [key: string]: number } = {};

            this.data.forEach((e: any) => {
                const date = new Date(e.date);
                const monthKey = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`; // Format: YYYY-MM
                groupedData[monthKey] = (groupedData[monthKey] || 0) + parseInt(e.new_visitors, 10);
            });
            console.log(groupedData)

            this.series = [{
                name: 'Nieuwe bezoekers',
                data: Object.entries(groupedData).map(([month, visitors]) => [month, visitors])
            }];
        }

        this.loading = false;
    }
}
